<template>
  <div>
    <v-list>
      <span class="text-subtitle-1"> Vorschläge </span>
      <div v-if="userUnits">
        <span class="text-subtitle-1"> Meiste Erfahrung in {{ formattedUserUnits }} </span>
        <v-list-item
          lines="two"
          class="mb-2"
          data-cy="userUnit"
          :active="inputVal === userUnits.id"
          @click="inputVal = userUnits.id"
        >
          <template #prepend>
            <profile-photo
              :user-id="userUnits.id"
              data-cy="avatar"
              readonly
              :size="40"
              class="mr-3"
            />
          </template>

          <v-list-item-title>
            {{ formatUserName(userUnits) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ Math.floor(userUnits.durationInUnits) }} Monate Erfahrung in
            {{ formattedUserUnits }}
          </v-list-item-subtitle>
        </v-list-item>
      </div>

      <div v-if="userBaseUnits">
        <span class="text-subtitle-1">
          Ähnlicher Bildungsstand wie {{ formatUserName(baseUser) }}
        </span>
        <v-list-item
          lines="two"
          class="mb-2"
          data-cy="userBaseUnits"
          :active="inputVal === userBaseUnits.id"
          @click="inputVal = userBaseUnits.id"
        >
          <template #prepend>
            <profile-photo
              :user-id="userBaseUnits.id"
              data-cy="avatar"
              readonly
              :size="40"
              class="mr-3"
            />
          </template>
          <v-list-item-title>
            {{ formatUserName(userBaseUnits) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ Math.floor(userBaseUnits.durationInBaseUserUnits) }} Monate Erfahrung in
            {{ formattedUserBaseUnits }}
          </v-list-item-subtitle>
        </v-list-item>
      </div>

      <div v-if="userTotal">
        <span class="text-subtitle-1"> Insgesamt meiste Erfahrung </span>
        <v-list-item
          data-cy="userTotal"
          lines="two"
          class="mb-2"
          :active="inputVal === userTotal.id"
          @click="inputVal = userTotal.id"
        >
          <template #prepend>
            <profile-photo
              :user-id="userTotal.id"
              data-cy="avatar"
              readonly
              :size="40"
              class="mr-3"
            />
          </template>
          <v-list-item-title>
            {{ formatUserName(userTotal) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ Math.floor(userTotal.totalDuration) }} Monate Erfahrung
          </v-list-item-subtitle>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>
<script>
import { differenceInCalendarMonths, addDays } from 'date-fns'
export default {
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    user: {
      type: Object,
      required: true,
    },
    entries: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      baseUser: null,
      userUnits: null,
      userBaseUnits: null,
      userTotal: null,
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
    formattedUserUnits() {
      let units = this.entries.map((entry) => entry.unit)
      units = units.filter(
        (unit, index) => index === units.findIndex((other) => unit.id === other.id)
      )
      return units.map((unit) => unit.short_name).join(', ')
    },
    formattedUserBaseUnits() {
      let units = this.userBaseUnits.schedule_history
        .filter((entry) => entry.unit)
        .map((entry) => entry.unit)
      units = units.filter(
        (unit, index) => index === units.findIndex((other) => unit.id === other.id)
      )
      return units.map((unit) => unit.short_name).join(', ')
    },
  },
  async mounted() {
    const response = await this.$cms.request(
      this.$readUsers({
        filter: {
          _and: [
            {
              status: {
                _neq: 'archived',
              },
            },
            {
              role: {
                identifier: {
                  _eq: 'ROTATING_STAFF',
                },
              },
            },
          ],
        },
        fields: [
          'id',
          'gender',
          'title',
          'first_name',
          'last_name',
          'email',
          'schedule_history.id',
          'schedule_history.status',
          'schedule_history.start',
          'schedule_history.type',
          'schedule_history.specialities.id',
          'schedule_history.specialities.speciality_id.id',
          'schedule_history.specialities.speciality_id.name',
          'schedule_history.end',
          'schedule_history.percentage',
          'schedule_history.extend_months',
          'schedule_history.request',
          'schedule_history.unit.id',
          'schedule_history.unit.short_name',
          'schedule_history.rotation_type',
          'schedule_history.overwrite',
        ],
        sort: ['id'],
        deep: {
          schedule_history: {
            _filter: {
              _and: [
                {
                  start: {
                    _lte: new Date().toISOString().substring(0, 10),
                  },
                },
                {
                  status: {
                    _neq: 'rejected',
                  },
                },
              ],
            },
            _sort: ['-start'],
          },
        },
      })
    )
    this.baseUser = response.find((user) => user.id === this.user.id)
    if (this.baseUser && this.entries) {
      const units = this.entries.filter((entry) => entry.unit).map((entry) => entry.unit.id)
      const baseUserUnits = this.baseUser.schedule_history
        .filter((entry) => entry.unit)
        .map((entry) => entry.unit.id)
      let users = response
        .filter(
          (user) =>
            user.id !== this.user.id &&
            (!user.schedule_history.length ||
              !user.schedule_history[0].unit ||
              !units.includes(user.schedule_history[0].unit.id))
        )
        .map((user) => {
          const userEntries = user.schedule_history.map((entry) => {
            const start = this.parseDate(entry.start)
            const end = this.parseDate(entry.end)
            const months = differenceInCalendarMonths(addDays(end, 1), start)
            return {
              ...entry,
              duration: months * (entry.percentage ? entry.percentage / 100 : 1),
            }
          })
          return {
            ...user,
            totalDuration: userEntries.map((entry) => entry.duration).reduce((a, b) => a + b, 0),
            durationInUnits: userEntries
              .filter((entry) => entry.unit && units.includes(entry.unit.id))
              .map((entry) => entry.duration)
              .reduce((a, b) => a + b, 0),
            durationInBaseUserUnits: userEntries
              .filter((entry) => entry.unit && baseUserUnits.includes(entry.unit.id))
              .map((entry) => entry.duration)
              .reduce((a, b) => a + b, 0),
          }
        })
      this.userUnits = users.reduce(
        (a, b) => (a && b && a.durationInUnits > b.durationInUnits ? a : b),
        null
      )
      if (this.userUnits) {
        users = users.filter((user) => user.id !== this.userUnits.id)
      }
      this.userBaseUnits = users.reduce(
        (a, b) => (a && b && a.durationInBaseUserUnits > b.durationInBaseUserUnits ? a : b),
        null
      )
      if (this.userBaseUnits) {
        users = users.filter((user) => user.id !== this.userBaseUnits.id)
      }
      this.userTotal = users.reduce(
        (a, b) => (a && b && a.totalDuration > b.totalDuration ? a : b),
        null
      )
    }
  },
  methods: {},
}
</script>

<style lang="scss"></style>
