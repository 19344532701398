<template>
  <app-side-bar
    v-model="open"
    :title="'Stellvertretung für ' + formatUserName(timeSpan.user)"
    order="10"
  >
    <v-container>
      <span
        v-if="!entries.length"
        class="text-subtitle-1"
      >
        keine Rotationen im Zeitraum gefunden
      </span>
      <template v-else>
        <span class="text-subtitle-1"> Rotationen im Zeitraum </span>
        <v-row
          v-for="entry in entries.slice(0, 2)"
          :key="entry.id"
          align="center"
          dense
        >
          <v-col cols="11">
            <schedule-history-card :entry="entry" />
          </v-col>
          <v-col cols="1">
            <schedule-occupation
              class="mx-4"
              :unit-id="entry.unit.id"
              :date="entry.start"
            />
          </v-col>
        </v-row>

        <v-expansion-panels
          v-if="entries.length > 2"
          variant="accordion"
          class="mt-1 mb-4"
        >
          <v-expansion-panel
            :title="entries.length - 2 + ' Weitere einblenden'"
            elevation="0"
          >
            <v-expansion-panel-text class="content-no-padding">
              <v-row
                v-for="entry in entries.slice(2)"
                :key="entry.id"
                align="center"
                dense
              >
                <v-col cols="11">
                  <schedule-history-card :entry="entry" />
                </v-col>
                <v-col cols="1">
                  <schedule-occupation
                    class="mx-4"
                    :unit-id="entry.unit.id"
                    :date="entry.start"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>

        <span class="text-subtitle-1"> Vertretung auswählen </span>
        <select-rotating
          v-model="selectedUser"
          data-cy="selectPhysician"
          :loading="loading"
          height="72"
        />
        <v-row
          v-for="substituteEntry in substituteEntries.slice(0, 2)"
          :key="substituteEntry.id"
          align="center"
          dense
        >
          <v-col cols="11">
            <schedule-history-card :entry="substituteEntry" />
          </v-col>
          <v-col cols="1">
            <schedule-occupation
              class="mx-4"
              :unit-id="substituteEntry.unit.id"
              :date="substituteEntry.start"
            />
          </v-col>
        </v-row>

        <v-expansion-panels
          v-if="substituteEntries.length > 2"
          variant="accordion"
          class="mt-1 mb-4"
        >
          <v-expansion-panel
            :title="substituteEntries.length - 2 + ' Weitere einblenden'"
            elevation="0"
          >
            <v-expansion-panel-text class="content-no-padding">
              <v-row
                v-for="substituteEntry in substituteEntries.slice(2)"
                :key="substituteEntry.id"
                align="center"
                dense
              >
                <v-col cols="11">
                  <schedule-history-card :entry="substituteEntry" />
                </v-col>
                <v-col cols="1">
                  <schedule-occupation
                    class="mx-4"
                    :unit-id="substituteEntry.unit.id"
                    :date="substituteEntry.start"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>

        <schedule-similar-rotating
          v-model="selectedUser"
          :entries="entries"
          :user="timeSpan.user"
          class="mt-10"
        />
      </template>

      <v-row class="mt-10">
        <v-col>
          <v-btn
            v-if="selectedUser"
            color="primary"
            variant="outlined"
            :loading="buttonLoading"
            data-cy="approveSubstituteOverride"
            @click="setSubstitute"
          >
            {{ confirmLabel }}
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col>
          <v-btn
            color="error"
            variant="outlined"
            data-cy="approveTimePeriodSubstitute"
            @click="$emit('confirm')"
          >
            {{ confirmWithoutSubstituteLabel }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </app-side-bar>
</template>
<script>
import { min, max } from 'date-fns'
export default {
  props: {
    modelValue: {
      type: Boolean,
    },
    timeSpan: {
      type: Object,
      default: null,
    },
    confirmLabel: {
      type: String,
      default: 'Vertretung speichern',
    },
    confirmWithoutSubstituteLabel: {
      type: String,
      default: 'Ohne Vertretung akzeptieren',
    },
  },
  emits: ['update:modelValue', 'confirm', 'close'],
  data() {
    return {
      entries: [],
      substituteEntries: [],
      selectedUser: null,
      loading: false,
      buttonLoading: false,
    }
  },
  computed: {
    open: {
      get() {
        return !!this.modelValue
      },
      set(newValue) {
        if (!newValue) {
          this.$emit('update:modelValue', null)
          this.$emit('close')
        }
      },
    },
  },
  watch: {
    selectedUser() {
      this.getSubstituteEntries()
    },
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      this.entries = await this.getEntries(this.timeSpan.user.id)
    },
    async getEntries(userId) {
      const response = await this.$cms.request(
        this.$readItems('schedule_history', {
          filter: {
            _and: [
              {
                end: {
                  _gte: this.timeSpan.start,
                },
              },
              {
                start: {
                  _lte: this.timeSpan.end,
                },
              },
              {
                status: {
                  _neq: 'rejected',
                },
              },
              {
                user: {
                  _eq: userId,
                },
              },
              {
                unit: { _nnull: true },
              },
              {
                overwrite: {
                  _eq: false,
                },
              },
            ],
          },
          fields: [
            'id',
            'status',
            'start',
            'type',
            'specialities.id',
            'specialities.speciality_id.id',
            'specialities.speciality_id.name',
            'end',
            'percentage',
            'extend_months',
            'request',
            'unit.id',
            'unit.short_name',
            'rotation_type',
            'schedule_section.id',
            'schedule_section.phase',
            'schedule_section.priority',
            'overwrite',
          ],
          sort: ['start'],
        })
      )
      return response
    },
    async getSubstituteEntries() {
      this.loading = true
      this.substituteEntries = []
      if (this.selectedUser) {
        this.substituteEntries = await this.getEntries(this.selectedUser)
      }
      this.loading = false
    },
    async setSubstitute() {
      this.buttonLoading = true
      const overrideEntries = [
        {
          start: this.timeSpan.start,
          end: this.timeSpan.end,
          status: 'confirmed',
          user: this.timeSpan.user.id,
          percentage: 0,
          overwrite: true,
          overwrite_time_period: this.timeSpan.id,
        },
      ]
      if (this.entries.length) {
        this.entries.forEach((entry) => {
          const start = max([this.parseDate(this.timeSpan.start), this.parseDate(entry.start)])
          const end = min([this.parseDate(this.timeSpan.end), this.parseDate(entry.end)])
          overrideEntries.push({
            start: start.toISOString().substring(0, 10),
            end: end.toISOString().substring(0, 10),
            status: 'confirmed',
            user: this.selectedUser,
            percentage: entry.percentage,
            unit: entry.unit.id,
            type: entry.type,
            specialities: entry.specialities.map((speciality) => {
              return { speciality_id: speciality.speciality_id.id }
            }),
            rotation_type: entry.rotation_type,
            overwrite: true,
            overwrite_time_period: this.timeSpan.id,
          })
        })
      }
      await this.$cms.request(this.$createItems('schedule_history', overrideEntries))
      this.buttonLoading = false
      this.$emit('confirm')
    },
  },
}
</script>
<style>
.content-no-padding .v-expansion-panel-text__wrapper {
  padding: 3px 0;
}
</style>
