<template>
  <div>
    <v-tooltip location="top">
      <template #activator="{ props }">
        <v-avatar
          v-bind="props"
          size="15"
          :color="getColor(unit.occupancy, unit.minimum_vacancies, unit.maximum_vacancies)"
        />
      </template>
      Anzahl AiWs: {{ unit.entries.length }}<br />
      <span v-if="unit.occupancy !== null"
        >Besetzung: {{ unit.occupancy.toLocaleString('de') }}<br
      /></span>
      <span v-if="unit.minimum_vacancies !== null"
        >Minimalbesetzung: {{ unit.minimum_vacancies.toLocaleString('de') }}<br
      /></span>
      <span v-if="unit.maximum_vacancies !== null"
        >Maximalbesetzung: {{ unit.maximum_vacancies.toLocaleString('de') }}</span
      >
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    unit: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getColor(occupation, min, max) {
      if (occupation < min) {
        return 'error'
      } else if (occupation <= max) {
        return 'success'
      } else {
        return 'warning'
      }
    },
  },
}
</script>
