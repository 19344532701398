<template>
  <schedule-occupation-dot
    v-if="unit"
    :unit="unit"
  />
</template>
<script>
export default {
  props: {
    unitId: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      unit: null,
    }
  },
  async mounted() {
    const unit = await this.$cms.request(
      this.$readItem('unit', this.unitId, {
        fields: [
          'id',
          'short_name',
          'long_name',
          'minimum_vacancies',
          'maximum_vacancies',
          'facility',
          'rotation_types',
          'schedule_history.id',
          'schedule_history.user.id',
          'schedule_history.user.first_name',
          'schedule_history.user.last_name',
          'schedule_history.user.phone_number',
          'schedule_history.user.email',
          'schedule_history.user.title',
          'schedule_history.user.gender',
          'schedule_history.rotation_type',
          'schedule_history.start',
          'schedule_history.end',
          'schedule_history.status',
          'schedule_history.percentage',
          'schedule_history.request',
          'schedule_history.extend_months',
          'schedule_history.schedule_section.id',
          'schedule_history.schedule_section.phase',
          'schedule_history.schedule_section.priority',
        ],
        deep: {
          schedule_history: {
            _filter: {
              _and: [
                {
                  end: {
                    _gte: this.date,
                  },
                },
                {
                  start: {
                    _lte: this.date,
                  },
                },
                {
                  status: {
                    _neq: 'rejected',
                  },
                },
              ],
            },
            _sort: ['start'],
          },
        },
      })
    )
    this.unit = {
      ...unit,
      entries: unit.schedule_history,
      occupancy: unit.schedule_history
        .map((entry) => (entry.percentage ? entry.percentage / 100 : 1))
        .reduce((a, b) => a + b, 0),
    }
  },
}
</script>
