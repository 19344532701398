<template>
  <v-row>
    <v-col>
      <v-progress-linear
        v-show="loading"
        indeterminate
        color="primary"
      />
      <v-autocomplete
        v-model="inputVal"
        :error-messages="errorMessages"
        :items="users"
        item-value="id"
        item-title="text"
        label="AiW auswählen"
        data-cy="chooseDoc"
        clearable
        variant="outlined"
        clear-on-select
        single-line
      >
        <template #item="{ props, item }">
          <v-list-item v-bind="props">
            <template #prepend>
              <profile-photo
                :user-id="item.raw.id"
                :skip-loading="!item.raw.avatar"
                class="mr-3"
                data-cy="avatar"
                readonly
                :size="40"
              />
            </template>
          </v-list-item>
        </template>

        <template #selection="{ item }">
          <v-list-item class="my-n3">
            <template #prepend>
              <profile-photo
                :user-id="item.raw.id"
                class="mr-3"
                data-cy="avatar"
                readonly
                :size="40"
              />
            </template>
            <v-list-item-title class="text-left">
              {{ formatUserName(item.raw) }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    minExperience: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      users: [],
      loading: false,
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  async mounted() {
    this.loading = true
    await this.$cms
      .request(
        this.$readUsers({
          filter: {
            _and: [
              {
                status: {
                  _neq: 'archived',
                },
              },
              {
                facilities: {
                  facility_id: {
                    users: {
                      directus_users_id: {
                        _eq: this.currentUser.id,
                      },
                    },
                  },
                },
              },
              {
                role: {
                  identifier: {
                    _eq: 'ROTATING_STAFF',
                  },
                },
              },
            ],
          },
          fields: ['id', 'avatar', 'gender', 'title', 'first_name', 'last_name', 'email'],
          sort: ['last_name'],
        })
      )
      .then((response) => {
        this.users = response.map((user) => {
          return {
            ...user,
            text: this.formatUserName(user),
          }
        })
        this.loading = null
      })
      .finally(() => {
        this.loading = false
      })
  },
}
</script>
